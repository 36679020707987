import React from "react";

const Favicon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 57 49'>
      <path fill='#F2B705' d='M11 0h46L46 49H0L11 0Z' />
      <path
        fill='#3C3C3C'
        d='M13.6 31.08c0-.32.213-.48.64-.48.427 0 1.747.293 3.96.88.507-4.373 1.933-9.12 4.28-14.24.827-1.867 1.813-2.8 2.96-2.8.213 0 .413.227.6.68.213.453.32 1.013.32 1.68 0 .8-.427 3-1.28 6.6-.827 3.6-1.373 6.733-1.64 9.4 1.76.4 3.307.6 4.64.6 1.36 0 2.6-.347 3.72-1.04a10.49 10.49 0 0 0 2.88-2.76c1.653-2.373 2.48-5.227 2.48-8.56-.027-6.16-2.48-9.24-7.36-9.24-1.733.027-4.8.8-9.2 2.32-.853.293-1.6.44-2.24.44-.64 0-.96-.187-.96-.56 0-.507.373-1.107 1.12-1.8.747-.693 1.707-1.36 2.88-2 2.88-1.547 5.68-2.32 8.4-2.32 3.733 0 6.8 1 9.2 3 2.827 2.373 4.227 5.973 4.2 10.8 0 2.053-.32 4.08-.96 6.08-.64 2-1.613 3.787-2.92 5.36-1.307 1.547-2.96 2.8-4.96 3.76s-4.36 1.44-7.08 1.44c-1.093 0-2.48-.24-4.16-.72-.187.507-.653.76-1.4.76-1.387 0-2.333-.227-2.84-.68-.48-.48-.76-1.253-.84-2.32-1.28-.72-2.347-1.467-3.2-2.24-.827-.773-1.24-1.453-1.24-2.04Z'
      />
    </svg>
  );
};

export default Favicon;
