// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--22w0l";
export var footerInner = "styles-module--footerInner--3Hnqd";
export var favicon = "styles-module--favicon--20z89";
export var overview = "styles-module--overview--2FeN9";
export var faviconBox = "styles-module--faviconBox--2pPcz";
export var contactAnchors = "styles-module--contactAnchors--35D-q";
export var navLinks = "styles-module--navLinks--RQ6cK";
export var linksTitle = "styles-module--linksTitle--y1No6";
export var socialLinks = "styles-module--socialLinks--2caWq";
export var socialLink = "styles-module--socialLink--L3nGn";
export var formGroup = "styles-module--formGroup--3WaUz";
export var attribution = "styles-module--attribution--3A9Js";