import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import Seo from "../SEO";
import "./styles.module.scss";
// const styles = require("./styles.module.scss");

const Layout = (props: any) => {
  return (
    <div className=''>
      <Seo />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
