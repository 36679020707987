import React from 'react'

const OpenIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.25 25.25'>
      <g fill='none' stroke='#051c40' strokeLinecap='round'>
        <path strokeWidth='3.5' d='M11.5 1.75h20' />
        <path strokeWidth='3' d='M1.5 12.75h30M1.5 23.75h30' />
      </g>
    </svg>
  )
}

export default OpenIcon
