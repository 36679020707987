import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Logo from "../svg/Logo";
import OpenIcon from "./../svg/Open";
import CloseIcon from "./../svg/Close";
const styles = require("./styles.module.scss");

const Header = (): JSX.Element => {
  const { site }: any = useStaticQuery(graphql`
    query HeaderMenu {
      site {
        siteMetadata {
          headerMenu {
            link
            title
          }
        }
      }
    }
  `);

  interface MenuLink {
    title: string;
    link: string;
  }

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderNavLinks: JSX.Element = site.siteMetadata.headerMenu.map(
    ({ title, link }: MenuLink): React.ReactNode => (
      <li className={styles.linkItem} key={title}>
        <Link to={link} onClick={() => setIsOpen(false)}>
          {title}
        </Link>
      </li>
    )
  );

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <nav className={styles.navbar}>
          <ul>
            <li>
              <Link to={`/`} className={styles.logo}>
                <Logo />
              </Link>
              <span className={styles.hideText}>
                Let's develop a winning web or mobile app for your business
              </span>
            </li>
          </ul>
          <ul className={`${styles.menu} ${isOpen ? styles.expand : ""}`}>
            {renderNavLinks}
          </ul>
          <button
            className={styles.menuToggler}
            title='Menu'
            aria-label='toggle menu'
            onClick={() => setIsOpen((cs) => !cs)}
          >
            {isOpen ? <CloseIcon /> : <OpenIcon />}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
