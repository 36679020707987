import React, { ReactNode } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import Favicon from "../svg/Favicon";
const styles = require("./styles.module.scss");

const Footer = (): JSX.Element => {
  const { site }: any = useStaticQuery(graphql`
    query FooterMenu {
      site {
        siteMetadata {
          footerMenu {
            link
            title
          }
          socials {
            facebook
            linkedIn
            twitter
          }
        }
      }
    }
  `);

  interface MenuLink {
    title: string;
    link: string;
  }

  const socialIcons: any = {
    twitter: <FontAwesomeIcon icon={faTwitter} size={"lg"} />,
    linkedIn: <FontAwesomeIcon icon={faLinkedin} size={"lg"} />,
    facebook: <FontAwesomeIcon icon={faFacebook} size={"lg"} />,
  };

  const renderNavLinks: ReactNode[] = site.siteMetadata.footerMenu.map(
    ({ title, link }: MenuLink): React.ReactNode => (
      <li className={styles.linkItem} key={title}>
        <Link to={link}>{title}</Link>
      </li>
    )
  );

  const renderSocialLinks: ReactNode[] = Object.entries(
    site.siteMetadata.socials
  ).map(([key, value]) => (
    <li key={key} className={styles.socialLink}>
      <a href={value} target='_blank' rel='noreferrer noopenner'>
        {socialIcons[key]}
      </a>
    </li>
  ));

  const currentYear: number = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.overview}>
          <div className={styles.faviconBox}>
            <Link to='/' title='Go Home' className={styles.favicon}>
              <Favicon />
            </Link>
          </div>
          <div>
            <p>
              Dewscope is an award-winning web and mobile app development agency
              based in Abuja, Nigeria.
            </p>
            <div className={styles.contactAnchors}>
              <a href='mailto:info@dewscope.com'>Email: info@dewscope.com</a>
              <a href='tel:+2349093258545'>Telephone: +234-9093-258-545</a>
            </div>
          </div>
        </div>
        <nav className={styles.navLinks}>
          <h3 className={styles.linksTitle}>Company</h3>
          <ul>{renderNavLinks}</ul>
        </nav>
        <div>
          <h3 className={styles.linksTitle}>Community</h3>
          {/* <ul className={styles.socialLinks}>{renderSocialLinks}</ul> */}
          <p>Join the community. Subscribe to our Newsletter</p>
          <form>
            <div className={styles.formGroup}>
              <input
                name='email'
                type='email'
                placeholder='Email address'
                aria-label='Email'
              />
              <button type='button'>Join</button>
            </div>
          </form>
        </div>
      </div>
      <p className={styles.attribution}>
        &copy;{currentYear} Dewscope Digital Services | All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
