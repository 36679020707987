import React from 'react'

const CloseIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 22.121'>
      <g fill='none'>
        <g stroke='#051c40' strokeLinecap='round' strokeWidth='3'>
          <path d='M6 19.799 23.678 2.121M6 2.121l17.678 17.678' />
        </g>
        <path d='M0 .121h30v22H0z' />
      </g>
    </svg>
  )
}

export default CloseIcon
